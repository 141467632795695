import { isPersistent, readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { writeSettings } from "@/app/actions.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import ValorantConnectedProfiles from "@/data-models/valorant-connected-profiles.mts";
import * as API from "@/game-val/api.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import { valorantRefs } from "@/game-val/refs.mjs";
import { formatConnectedProfile } from "@/game-val/utils.mjs";

async function fetchPrivacySetting() {
  const loggedInAccounts = readState.settings.loggedInAccounts.valorant;
  const bearerToken = await valorantRefs.getBearerToken();
  if (!bearerToken) return;

  const connectedProfiles = await getData(
    API.getConnectedProfiles({}),
    ValorantConnectedProfiles,
    undefined,
    { headers: { Authorization: bearerToken } },
  );

  const newLoggedInAccounts = {};
  for (const connectedProfile of connectedProfiles) {
    const profile = connectedProfile.riotAccount;
    profile[isPersistent] = true;
    if (!profile.valorantProfile?.internalUuid) continue;
    newLoggedInAccounts[profile.valorantProfile.internalUuid] =
      formatConnectedProfile(profile);
  }

  for (const accountKey in loggedInAccounts) {
    if (newLoggedInAccounts[accountKey]) continue;
    newLoggedInAccounts[accountKey] = {
      ...loggedInAccounts[accountKey],
      internalUuid: accountKey,
      isPrivate: null,
      userAccountId: null,
    };
  }

  writeSettings(
    ["loggedInAccounts", GAME_SHORT_NAMES[GAME_SYMBOL_VAL]],
    newLoggedInAccounts,
  );
}

export default fetchPrivacySetting;
